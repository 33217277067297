:root {
  --color-primary-darkest: #222;
  --color-primary-dark: #393939;
  --color-primary: #888;
  --color-primary-light: #ccc;
  --color-primary-lightest: #eee;
  --color-secondary: #edfdde;
  --color-white: #ffffff;
  --color-cloudy: #fbfaf9;
  --color-overcast: #dfdfe1;

  --ds-blue: #0d97ff; /* blue */

  --ds-orion-highlight: #da283a;
  --color-brand: #00aa55; /* green */
  --ds-ursa-highlight: #00954a;
  --ds-selective-yellow: #ffba00; /* **DEPRECATED** yellow */
  --ds-light-blue: #eaf8ff; /* **DEPRECATED** light blue punched up */

  --color-bg: #ffffff;
  --color-text-primary: var(--color-primary-darkest);
  --color-text-clue: var(--white);

  --color-bg-dark-theme: var(--color-primary-darkest);
  --color-text-dark-theme: var(--color-primary-lightest);
  --color-text-clue-dark-theme: var(--color-primary-lightest);

  --color-bg-green-theme: var(--color-brand);
  --color-text-green-theme: var(--color-white);
  --color-text-clue-green-theme: var(--color-white);

  --color-button-bg: var(--color-primary-darkest);
  --color-button-bg-hover: var(--color-primary-darkest);
  --color-button-text: var(--color-primary-darkest);
  --color-button-text-hover: var(--color-white);

  --color-border: var(--color-primary-darkest);
  --color-border-dark-theme: var(--color-cloudy);

  --color-blue: var(--ds-blue);
  --color-red: var(--ds-orion-highlight);

  --space: 16px;
  --space-xsmall: 4px;
  --space-small: 8px;
  --space-large: 32px;
  --space-xlarge: 48px;

  --font-size-xsmall: 10px;
  --font-size-small: 12px;
  --font-size-medium: 16px;
  --font-size-large: 22px;
  --font-size-xlarge: 28px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: var(--font-size-xlarge);
}

h2 {
  font-size: var(--font-size-large);
}

h3,
p {
  font-size: var(--font-size-medium);
}

h4 {
  font-size: var(--font-size-small);
}

h5,
h6 {
  font-size: var(--font-size-xsmall);
}

html {
  background-color: var(--color-bg);
}

a {
  color: var(--color-primary-dark);
}

.space {
  margin-top: var(--space);
}

.space-large {
  margin-top: var(--space-large);
}

.space-xlarge {
  margin-top: var(--space-xlarge);
}

.space-small {
  margin-top: var(--space-small);
}

.space-xsmall {
  margin-top: var(--space-xsmall);
}

input {
  text-align: center;
  border: none;
  display: block;
  border-bottom: 2px solid black;
  font-size: var(--font-size-large);
  font-weight: 500;

  margin: var(--space-large) auto var(--space);
}
