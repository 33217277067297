.game-preview {
  float: left;
  width: calc(33.3% - (var(--space-small) * 2));
  height: 350px;
  background: var(--color-primary-darkest);
  color: var(--color-primary-lightest);
  overflow: hidden;
  margin: var(--space-small);
  padding: var(--space-small);
  position: relative;
}

.game-preview .players-list {
  width: 100%;
  height: 200px;
  margin-top: var(--space);
  overflow: hidden;
}

.game-preview .players-list > div {
  margin-top: var(--space-small);
}

.game-preview button {
  margin-top: var(--space-small);
  width: calc(100% - (var(--space-small) * 2));
  width: 100%;
}

.game-preview .status-message {
  font-weight: 500;
}

.game-preview .and-more {
  display: block;
  font-weight: 800;
  font-size: var(--font-size-small);
}

.game-preview a {
  margin-top: var(--space-large);
  color: var(--color-primary-light);
}

.preview-bottom {
  text-align: center;
  position: absolute;
  bottom: var(--space-small);
  width: calc(100% - (var(--space-small) * 2));
}
