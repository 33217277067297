.timer-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.timer-wrapper.stage-1 {
  animation: blinkingText 2s infinite;
}

.timer-wrapper.stage-2 {
  animation: blinkingText 1s infinite;
}

.timer-wrapper.stage-3 {
  animation: blinkingText 0.5s infinite;
}

.timer-wrapper.stage-4 {
  animation: blinkingText 0.25s infinite;
}

@keyframes blinkingText {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  49% {
    background-color: rgba(255, 255, 255, 0.16);
  }
  60% {
    background-color: rgba(255, 255, 255, 0.16);
  }
  99% {
    background-color: rgba(255, 255, 255, 0);
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}
