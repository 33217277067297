.team-score {
  width: 200px;
  text-align: center;
  font-size: 80px;
  line-height: 80px;
  position: relative;
}

.team-score .main-score {
  position: relative;
}

.team-score .old-score {
  position: absolute;
  top: 0;
  width: 100%;
}

.team-score.animating .main-score {
  opacity: 0;
  animation: flip-in 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.team-score.animating .old-score {
  animation: flip-out 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.team-score .exploding-score {
  position: absolute;
  top: 0;
  width: 100%;
}
.team-score.animating .exploding-score {
  font-size: 90px;
  animation: exploding-score 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes flip-out {
  0% {
    transform: rotateX(0deg);
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    transform: rotateX(90deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(180deg);
    opacity: 0;
  }
}

@keyframes flip-in {
  0% {
    transform: rotateX(180deg);
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    transform: rotateX(270deg);
    opacity: 1;
  }
  100% {
    transform: rotateX(360deg);
    opacity: 1;
  }
}

@keyframes exploding-score {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
}
