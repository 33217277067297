.game-board.codenames {
  width: 100%;
}

.game-board.codenames .-red {
  color: var(--color-red);
}

.game-board.codenames .-blue {
  color: var(--color-blue);
}

.setup-container {
  max-width: 700px;
  margin: 0 auto;
}
.setup-container h3 {
  margin-bottom: var(--space);
}

.card-grid {
  width: 100%;
  overflow: hidden;
  margin-top: var(--space-large);
  padding: 0 50px;
}

.card-grid .card-grid-item {
  width: calc(20% - 4px);
  margin: 2px;
  height: 9vw;
  float: left;
  font-size: 1.6vw;
  font-weight: 600;
  /* border: 1px solid black; */
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  background-color: var(--color-primary-lightest);
  line-height: 9vw;
  color: var(--color-primary-dark);
}

@media (max-width: 1000px) {
  .card-grid {
    padding: 0px;
  }
}

.card-grid .card-grid-item.-clickable {
  cursor: pointer;
}

.card-grid .card-grid-item.-clickable:not(.-flipped):hover {
  background-color: var(--color-primary-light);
}

.card-grid .card-grid-item.-red-agent {
  background: var(--color-red);
  color: white;
}

.card-grid .card-grid-item.-blue-agent {
  background-color: var(--color-blue);
  color: white;
}

.card-grid .card-grid-item.-assassin-agent {
  background-color: black;
  color: white;
}

.card-grid .card-grid-item.-neutral-agent {
  background-color: var(--color-primary-light);
  color: white;
}

.card-grid .card-grid-item.-spymaster-view.-flipped {
  opacity: 0.04;
}

.codenames .rules {
  background: var(--color-primary-lightest);
  padding: 30px 40px;
  margin: 20px;
  text-align: left;
}
