button.button {
  cursor: pointer;
  padding: var(--space) var(--space-xlarge);
  border: 3px solid var(--color-button-bg);
  color: var(--color-button-text);
  border-radius: 4px;
  background: white;
  font-size: var(--font-size-medium);
  font-weight: 600;
  max-width: 500px;
}

button.button:hover {
  background-color: var(--color-button-bg-hover);
  color: var(--color-button-text-hover);
}

button.button:disabled {
  cursor: default;
  border-color: var(--color-primary-light);
  color: var(--color-primary-light);
}

button.button:disabled:hover {
  border-color: var(--color-primary-light);
  color: var(--color-primary-light);
  background-color: white;
}

button.button[data-color='red'] {
  color: var(--color-red);
  border-color: var(--color-red);
}

button.button[data-color='red']:hover {
  border-color: white;
  color: white;
  background-color: var(--color-red);
}

button.button[data-color='blue'] {
  color: var(--color-blue);
  border-color: var(--color-blue);
}

button.button[data-color='blue']:hover {
  border-color: white;
  color: white;
  background-color: var(--color-blue);
}
