.lobby {
  max-width: 1000px;
  min-width: 640px;
  margin: 0 auto;
}

.lobby-top {
  width: 100%;
  text-align: center;
}

.lobby-top button {
  margin: var(--space);
}

.lobby .games-list {
  margin-top: var(--space);
  width: 100%;
  overflow: hidden;
}

.lobby .games-list > h2 {
  margin-left: var(--space-small);
}
