.game-board {
  width: 100%;
}

.game-board .team-1,
.game-board .team-2 {
  width: 240px;
  float: left;
  padding: 20px;
}

.game-board .board-main {
  width: calc(100% - 480px);
  padding: 0 10px;
  float: left;
  text-align: center;
}

.game-board .team-2 {
  text-align: right;
}

.game-board .team-name {
  width: 200px;
  text-align: center;
  font-size: 30px;
  line-height: 80px;
}
.game-board .team-2 .team-name {
  float: right;
}

.game-board .team-2 .team-score {
  float: right;
}

.game-board .needs-score {
  width: 200px;
  text-align: center;
}

.game-board .team-2 .needs-score {
  float: right;
}

.game-board .player-card {
  margin-top: var(--space-small);
}

.game-board .first-to-7 {
  font-size: var(--font-size-xsmall);
}

.game-board .on-the-clock {
  margin-top: var(--space-large);
}

.game-board .clue {
  margin-top: 50px;
}

.game-board .clue h1 {
  width: 100%;
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  margin: 0;
  padding: 5px 30px;
  color: var(--color-text-clue);
  border-bottom: 2px solid var(--color-border);
}

.dark-theme .game-board .clue h1 {
  color: var(--color-text-clue-dark-theme);
  border-color: var(--color-border-dark-theme);
}

.green-theme .game-board .clue h1 {
  color: var(--color-text-clue-green-theme);
  border-color: var(--color-border-green-theme);
}

.game-board button {
  margin-top: var(--space-large);
  width: 100%;
  border-color: var(--color-brand);
  color: var(--color-brand);
}

.green-theme .game-board button {
  border-color: white;
  background-color: var(--color-brand);
  color: white;
}
.green-theme .game-board button:hover {
  border-color: white;
  background-color: white;
  color: var(--color-brand);
}

.game-board button:hover {
  background-color: var(--color-brand);
  color: var(--color-white);
}

.game-board .challenge-button .button {
  background: none;
}

.game-board .challenge-button .button:hover {
  background: var(--color-brand);
  color: white;
}
