.main {
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  background-color: var(--color-bg);
  color: var(--color-text-primary);
  max-width: 1400px;
  margin: 0 auto;
}
.catchphrase-board .main {
  max-width: none;
}

.main.dark-theme {
  background-color: var(--color-bg-dark-theme);
  color: var(--color-text-dark-theme);
}

.main.green-theme {
  background-color: var(--color-bg-green-theme);
  color: var(--color-text-green-theme);
}

.main-content {
  width: 100%;
  margin: 0px auto;
}

header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--space-xlarge);
  background-color: rgba(255, 255, 255, 0.95);
}

.main.dark-theme header {
  background-color: rgba(34, 34, 34, 0.95);
}

.main.green-theme header {
  background-color: rgba(0, 170, 85, 0.95);
}

header .logo {
  float: left;
  line-height: var(--space-xlarge);
  padding-left: var(--space);
}

.main.green-theme header .logo,
.main.dark-theme header .logo {
  color: white;
}

header .username {
  float: right;
  width: 200px;
  line-height: var(--space-xlarge);
  font-weight: 500;
}

.content-title {
  text-align: center;
  font-weight: 500;
}
